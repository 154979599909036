import Image from "next/image"
import { FaFacebookSquare, FaLinkedin, FaInstagramSquare, FaPinterestSquare, FaYoutubeSquare } from "react-icons/fa"
import Link from "next/link"
import { useWhatsApp } from "../../contexts/WhatsAppProvider"

export function Footer({ links }) {
  const { setOpenChat } = useWhatsApp()

  return (
    <div className="bg-[#F5F5F5]">
      <footer className="w-full py-7">
        <div className="container mx-auto w-full grid grid-cols-[repeat(auto-fill,minmax(200px,1fr))] gap-4">
          <div className="flex flex-col gap-4 w-full sm:col-span-3">
            <h2 className="text-sm font-bold uppercase">Brindes personalizados</h2>
            <ul className="columns-2">
              {links?.linkBrindes?.map((item, index) => (
                <li key={index} className="text-xs text-gray-500 py-1 leading-3">
                  <Link href={`/categoria/${item.url_site}/${item.codigo_grupo_produto}`}>
                    <a
                      href={`/categoria/${item.url_site}/${item.codigo_grupo_produto}`}
                      className="hover:underline cursor-pointer hover:text-[#0000EE]"
                    >
                      <span className="capitalize">{`${item.descricao_grupo_produto
                        ?.replace("PARA BRINDES", "")
                        .toLowerCase()}`}</span>
                      <span>para brindes</span>
                    </a>
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div className="flex flex-col gap-4 w-full">
            <h2 className="text-sm font-bold uppercase">Segmentos</h2>
            <ul className="flex flex-col">
              {links?.linkSegmentos?.map((item, index) => (
                <li key={index} className="text-xs text-gray-500 py-1 leading-3">
                  <Link href={item.url_segmento}>
                    <a href={item.url_segmento} className="hover:underline cursor-pointer hover:text-[#0000EE]">
                      {`${item.descricao} para brindes`}
                    </a>
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div className="flex flex-col gap-4 w-full">
            <div className="flex flex-col gap-4">
              <h2 className="text-sm font-bold uppercase">Atendimento</h2>
              <h3 className="max-w-[138px] w-full text-xs">
                Segunda a sexta-feira das 08:00 as 12:00 horas e 13:30 as 18 horas
              </h3>
            </div>

            <div className="flex flex-col gap-4">
              <h2 className="text-sm font-bold uppercase">CONTATOS</h2>
              <ul>
                <li className="text-xs text-gray-500 py-1 leading-3">
                  <div className="flex items-center gap-2">
                    Telefone: (11) <button onClick={() => setOpenChat(true)}>11 2649-6030</button>
                  </div>
                </li>
                <li className="text-xs text-gray-500 py-1 leading-3">
                  WhatsApp: <button onClick={() => setOpenChat(true)}>(11) 11 2649-6030</button>{" "}
                </li>
                <li className="text-xs text-gray-500 py-1 leading-3 whitespace-nowrap">
                  comercial@innovationbrindes.com.br
                </li>
              </ul>
            </div>

            <div className="flex flex-col gap-4">
              <h2 className="text-sm font-bold uppercase">MÍDIAS SOCIAIS</h2>
              <ul className="grid grid-cols-3 gap-2">
                <li className="text-xs text-gray-500 py-1 leading-3">
                  <a href="https://www.facebook.com/innovationbrindes" target="_blank" rel="noreferrer">
                    <FaFacebookSquare className="text-3xl text-gray-500" />
                  </a>
                </li>
                <li className="text-xs text-gray-500 py-1 leading-3">
                  <a href="https://www.linkedin.com/company/innovationbrindes/" target="_blank" rel="noreferrer">
                    <FaLinkedin className="text-3xl text-gray-500" />
                  </a>
                </li>
                <li className="text-xs text-gray-500 py-1 leading-3">
                  <a href="https://www.instagram.com/innovationbrindes/" target="_blank" rel="noreferrer">
                    <FaInstagramSquare className="text-3xl text-gray-500" />
                  </a>
                </li>
                <li className="text-xs text-gray-500 py-1 leading-3">
                  <a href="https://br.pinterest.com/innovationbrindes/" target="_blank" rel="noreferrer">
                    <FaPinterestSquare className="text-3xl text-gray-500" />
                  </a>
                </li>
                <li className="text-xs text-gray-500 py-1 leading-3">
                  <a href="https://www.youtube.com/c/InnovationbrindesBr" target="_blank" rel="noreferrer">
                    <FaYoutubeSquare className="text-3xl text-gray-500" />
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <h2 className="text-sm font-bold uppercase">É SÓ NA INNOVATION</h2>
            <ul>
              <li className="text-xs text-gray-500 py-1 leading-3">
                <Link href="/segmento/novidades/6">Lançamentos</Link>
              </li>
              <li className="text-xs text-gray-500 py-1 leading-3">
                <Link href="/ultra-rapido">Pronto em 48 horas</Link>
              </li>
              <li className="text-xs text-gray-500 py-1 leading-3">
                <Link href="/regulamento/compre-ganhe">Compre e ganhe</Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>

      <div className="bg-white w-full px-3">
        <div className="container mx-auto py-6 space-y-6">
          <h2 className="text-center text-xs">Formas de pagamento</h2>
          <div className="flex gap-4 items-center justify-center flex-wrap">
            <Image
              width={"48px"}
              height="26px"
              src="https://imgproductioncrm.s3.us-east-2.amazonaws.com/visa_4x.png"
              alt="visa"
            />
            <Image
              width={"48px"}
              height="26px"
              src="https://imgproductioncrm.s3.us-east-2.amazonaws.com/mastercard_4x.png"
              alt="mastercard"
            />
            <Image
              width={"48px"}
              height="26px"
              src="https://imgproductioncrm.s3.us-east-2.amazonaws.com/american_4x.png"
              alt="american"
            />
            <Image
              width={"48px"}
              height="26px"
              src="https://imgproductioncrm.s3.us-east-2.amazonaws.com/discover_4x.png"
              alt="discover"
            />
            <Image
              width={"48px"}
              height="26px"
              src="https://imgproductioncrm.s3.us-east-2.amazonaws.com/dinners_4x.png"
              alt="diners"
            />
            <Image
              width={"48px"}
              height="26px"
              src="https://imgproductioncrm.s3.us-east-2.amazonaws.com/elo_4x.png"
              alt="elo"
            />
            <Image
              width={"48px"}
              height="26px"
              src="https://imgproductioncrm.s3.us-east-2.amazonaws.com/aura_4x.png"
              alt="aura"
            />
            <Image
              width={"48px"}
              height="26px"
              src="https://imgproductioncrm.s3.us-east-2.amazonaws.com/hipercard_4x.png"
              alt="hipercard_4x"
            />
            <Image
              width={"48px"}
              height="26px"
              src="https://imgproductioncrm.s3.us-east-2.amazonaws.com/jcb_4x.png"
              alt="jcb"
            />
            <Image
              width={"48px"}
              height="26px"
              src="https://imgproductioncrm.s3.us-east-2.amazonaws.com/pix_4x.png"
              alt="pix"
            />
            <Image
              width={"48px"}
              height="26px"
              src="https://imgproductioncrm.s3.us-east-2.amazonaws.com/boleto_4x.png"
              alt="boleto"
            />
          </div>
        </div>
      </div>

      <div className="container bg-[#F5F5F5] mx-auto flex items-center justify-center gap-6 flex-wrap py-2">
        <a
          href="https://transparencyreport.google.com/safe-browsing/search?url=innovationbrindes.com.br
"
          target="_blank"
          rel="noreferrer"
        >
          <Image
            className="onePerRow"
            src="https://imgproductioncrm.s3.us-east-2.amazonaws.com/safe%20%281%29.png1722973408.345"
            alt="Google Safe Browsing"
            width={130}
            height={70}
            objectFit="contain"
          />
        </a>
        <Image
          className="twoPerRow"
          src="https://imgproductioncrm.s3.us-east-2.amazonaws.com/Image%2010%20%281%29.png1722973408.4311"
          alt="Site Seguro"
          width={130}
          height={70}
          objectFit="contain"
        />
        <a href="https://g.page/r/CRRx_rrlky81EBM/review" target="_blank" rel="noreferrer">
          <Image
            className="treePerRow"
            src="https://imgproductioncrm.s3.us-east-2.amazonaws.com/google%20%281%29.png1722973408.4036"
            alt="Google Reviews"
            width={110}
            height={70}
            objectFit="contain"
          />
        </a>
      </div>
    </div>
  )
}
